import { useState } from 'react';
import { supabase } from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useSnackbar } from '../../context/SnackbarContext';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();

    const validatePassword = (password) => {
        if (password.length < 6) {
            return 'Password must be at least 6 characters long';
        }
        return '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Reset error state
        setError('');

        // Validate password
        const passwordError = validatePassword(password);
        if (passwordError) {
            setError(passwordError);
            return;
        }

        // Check if passwords match
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        setIsSubmitting(true);

        try {
            const { error } = await supabase.auth.updateUser({
                password: password
            });

            if (error) throw error;

            showSnackbar('Password updated successfully');
            navigate('/user/settings');
        } catch (error) {
            console.error('Error resetting password:', error);
            setError(error.message || 'Failed to reset password');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
       
            <Box sx={{ 
                flex: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center', 
                padding: 4 
            }}>
                <Box sx={{ maxWidth: '400px', width: '100%' }}>
                    <Typography variant="h5" sx={{ mb: 3 }}>
                        Reset Your Password
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
                        Please enter your new password below.
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit}>
                        <TextField
                            label="New Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                            required
                            margin="normal"
                            error={!!error}
                        />
                        <TextField
                            label="Confirm New Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            fullWidth
                            required
                            margin="normal"
                            error={!!error}
                            helperText={error}
                        />
                        <Button 
                            type="submit" 
                            variant="contained" 
                            color="primary"
                            fullWidth
                            size="large"
                            disabled={isSubmitting}
                            sx={{ mt: 2 }}
                        >
                            {isSubmitting ? 'Updating...' : 'Update Password'}
                        </Button>
                    </Box>
                </Box>
            </Box>
            
    );
};

export default ResetPassword; 