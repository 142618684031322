import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

const TenantContext = createContext();

export function TenantProvider({ children }) {
    const [activeTenantId, setActiveTenantId] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchActiveTenant = async () => {
            try {
                const { data: { session } } = await supabase.auth.getSession();
                if (!session) return;

                const { data, error } = await supabase
                    .from('users')
                    .select('active_tenant_id')
                    .eq('id', session.user.id)
                    .single();

                if (error) throw error;
                setActiveTenantId(data.active_tenant_id);
            } catch (error) {
                console.error('Error fetching active tenant:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchActiveTenant();

        // Listen for tenant switches
        const handleTenantSwitch = (event) => {
            setActiveTenantId(event.detail.tenantId);
        };

        window.addEventListener('tenantSwitch', handleTenantSwitch);
        return () => window.removeEventListener('tenantSwitch', handleTenantSwitch);
    }, []);

    return (
        <TenantContext.Provider value={{ activeTenantId, loading }}>
            {children}
        </TenantContext.Provider>
    );
}

export function useTenant() {
    const context = useContext(TenantContext);
    if (context === undefined) {
        throw new Error('useTenant must be used within a TenantProvider');
    }
    return context;
} 