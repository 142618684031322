import React, { useState, useEffect } from 'react';
import { 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Box, 
  CircularProgress,
  TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { supabase } from '../../../../supabaseClient';
import { useAuth } from '../../../../context/AuthContext';

const DataSourceConfig = ({ funnel, handleDataSourceChange, API_URL, activeTenantId }) => {
  const [hubspotLists, setHubspotLists] = useState([]);
  const [loadingLists, setLoadingLists] = useState(false);
  const [csvFiles, setCsvFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const { session, refreshToken, isTokenExpired } = useAuth();

  useEffect(() => {
    if (funnel.data_source.source.type === 'HubSpot List' && activeTenantId) {
      fetchHubspotLists();
    } else if (funnel.data_source.source.type === 'CSV' && activeTenantId) {
      fetchCsvFiles();
    }
  }, [funnel.data_source.source.type, activeTenantId]);

  const fetchHubspotLists = async () => {
    setLoadingLists(true);
    try {
      if (!activeTenantId) {
        throw new Error('No tenant ID found');
      }

      if (isTokenExpired(session)) {
        await refreshToken();
      }
      
      const response = await fetch(`${API_URL}/api/hubspot/lists/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session?.access_token}`
        },
        body: JSON.stringify({
          tenant_id: activeTenantId
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.detail || 'Failed to fetch HubSpot lists');
      }

      const data = await response.json();
      setHubspotLists(data.lists || []);
    } catch (error) {
      console.error('Error fetching HubSpot lists:', error);
      setHubspotLists([]);
    } finally {
      setLoadingLists(false);
    }
  };

  const fetchCsvFiles = async () => {
    setLoadingFiles(true);
    try {
      if (!activeTenantId) {
        throw new Error('No tenant ID found');
      }
      
      // List files from the CSV_uploads bucket in the tenant's folder
      const { data: files, error } = await supabase
        .storage
        .from('CSV_uploads')
        .list(activeTenantId);

      if (error) throw error;

      // Filter only CSV files
      const csvFiles = files.filter(file => file.name.toLowerCase().endsWith('.csv'));
      setCsvFiles(csvFiles);
    } catch (error) {
      console.error('Error fetching CSV files:', error);
      setCsvFiles([]);
    } finally {
      setLoadingFiles(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 2, paddingTop: 2 }}>
      <FormControl sx={{ flex: 1 }} size="small">
        <InputLabel>Source Type</InputLabel>
        <Select
          value={funnel.data_source.source.type}
          label="Source Type"
          onChange={(e) => handleDataSourceChange('type', e.target.value)}
        >
          <MenuItem value="HubSpot List">HubSpot List</MenuItem>
          <MenuItem value="CSV">CSV</MenuItem>
        </Select>
      </FormControl>

      {funnel.data_source.source.type === 'HubSpot List' ? (
        <Autocomplete
          sx={{ flex: 2 }}
          options={hubspotLists}
          getOptionLabel={(option) => option.name || ''}
          loading={loadingLists}
          value={hubspotLists.find(list => list.id === funnel.data_source.source.value) || null}
          onChange={(_, newValue) => {
            handleDataSourceChange('value', newValue?.id || '');
            handleDataSourceChange('name', newValue?.name || '');
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="HubSpot List"
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingLists ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          filterOptions={(options, { inputValue }) => {
            const inputValueLower = inputValue.toLowerCase();
            return options.filter(option => 
              option.name.toLowerCase().includes(inputValueLower)
            );
          }}
        />
      ) : funnel.data_source.source.type === 'CSV' ? (
        <FormControl sx={{ flex: 2 }} size="small">
          <InputLabel>CSV File</InputLabel>
          <Select
            value={funnel.data_source.source.name}
            label="CSV File"
            onChange={(e) => {
              const selectedFile = csvFiles.find(file => file.name === e.target.value);
              handleDataSourceChange('name', e.target.value);
              handleDataSourceChange('value', selectedFile?.id || '');
            }}
            disabled={loadingFiles}
          >
            {loadingFiles ? (
              <MenuItem disabled>
                <CircularProgress size={20} /> Loading files...
              </MenuItem>
            ) : csvFiles.length === 0 ? (
              <MenuItem disabled>No CSV files found</MenuItem>
            ) : (
              csvFiles.map(file => (
                <MenuItem key={file.id} value={file.name}>
                  {file.name}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      ) : null}
    </Box>
  );
};

export default DataSourceConfig; 