import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    Paper, 
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useSnackbar } from '../../context/SnackbarContext';

const WorkflowDetails = () => {
    const { id } = useParams();
    const [executions, setExecutions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { showSnackbar } = useSnackbar();
    const API_URL = window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetchWorkflowDetails();
    }, [id]);

    const fetchWorkflowDetails = async () => {
        try {
            const { data: { session } } = await supabase.auth.getSession();
            
            const response = await fetch(`${API_URL}/api/workflows/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch workflow details');
            }

            const data = await response.json();
            setExecutions(data.executions || []);
        } catch (error) {
            console.error('Error fetching workflow details:', error);
            showSnackbar('Failed to load workflow details', 'error');
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        return new Date(dateString).toLocaleString();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Workflow Executions
            </Typography>

            <Paper sx={{ mt: 3 }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell>Started At</TableCell>
                                <TableCell>Stopped At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {executions.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        No executions found
                                    </TableCell>
                                </TableRow>
                            ) : (
                                executions
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((execution) => (
                                        <TableRow key={execution.id}>
                                            <TableCell>{execution.id}</TableCell>
                                            <TableCell>{formatDate(execution.name)}</TableCell>
                                            <TableCell>{execution.inputs}</TableCell>
                                            <TableCell>{execution.outputs}</TableCell>
                                        </TableRow>
                                    ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={executions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

export default WorkflowDetails; 