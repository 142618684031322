import React from 'react';
import UploadedFiles from './uploaded_files';
import { Box, Typography } from '@mui/material';

export const CohortMQLS = () => {
  

  return (
    <Box sx={{ paddingTop: 0, paddingLeft: 2 }}>
        <Typography variant="h4" component="h1">MQL Analysis (Cohorts)</Typography>
        <UploadedFiles />

    </Box>
      
     
 
  );
};

export default CohortMQLS;
