import { useState, useEffect } from 'react'
import { supabase } from './supabaseClient'
import { ThemeProvider, CssBaseline } from '@mui/material';
import Layout from './components/layout'
import Login from './components/auth/login'
import ForgotPassword from './components/auth/forgot-password'
import theme from './theme/theme';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { SnackbarProvider } from './context/SnackbarContext';
import { TenantProvider } from './context/TenantContext';
import { AuthProvider } from './context/AuthContext';
import './App.css';

export default function App() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="loading-container">
          <span className="loading-text">Loading...</span>
        </div>
      </ThemeProvider>
    );
  }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <AuthProvider>
            <TenantProvider>
              <CssBaseline />
              <Routes>
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/login" element={!session ? <Login /> : <Navigate to="/" />} />
                <Route 
                  path="/*" 
                  element={session ? <Layout session={session} /> : <Navigate to="/login" />} 
                />
              </Routes>
            </TenantProvider>
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
}
