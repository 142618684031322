import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Button,
    Avatar,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Switch,
    IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { supabase } from '../../supabaseClient';
import { useSnackbar } from '../../context/SnackbarContext';
import { useNavigate } from 'react-router-dom';

const UserSettings = () => {
    const API_URL = window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [userData, setUserData] = useState(null);
    const [name, setName] = useState('');
    const [avatarUrl, setAvatarUrl] = useState('');
    const [workspaces, setWorkspaces] = useState([]);
    const [pendingInvites, setPendingInvites] = useState([]);
    const fileInputRef = useRef(null);
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserData();
        fetchWorkspaces();
        fetchPendingInvites();
    }, []);

    const fetchUserData = async () => {
        try {
            const { data: { user }, error } = await supabase.auth.getUser();
            if (error) throw error;

            console.log('User metadata:', user.user_metadata);
            
            setUserData(user);
            setName(user.user_metadata?.name || '');
            setAvatarUrl(user.user_metadata?.avatar_url || '');
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
            showSnackbar('Failed to fetch user data', 'error');
            setLoading(false);
        }
    };

    const fetchWorkspaces = async () => {
        try {
            const { data: { user } } = await supabase.auth.getUser();
            
            const { data: userRolesData, error: rolesError } = await supabase
                .from('user_roles')
                .select(`
                    tenant_id,
                    is_default,
                    role,
                    tenants:tenant_id (
                        name
                    )
                `)
                .eq('user_id', user.id);

            if (rolesError) throw rolesError;

            console.log('User roles data:', userRolesData);

            setWorkspaces(userRolesData.map(t => ({
                id: t.tenant_id,
                name: t.tenants.name,
                isDefault: t.is_default,
                role: t.role
            })));
            setLoading(false);
        } catch (error) {
            console.error('Error fetching workspaces:', error);
            showSnackbar('Failed to fetch workspaces', 'error');
            setLoading(false);
        }
    };

    const fetchPendingInvites = async () => {
        try {
            const { data: { session } } = await supabase.auth.getSession();
            
            const response = await fetch(`${API_URL}/api/users/getpendinginvites`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    user_id: session.user.id
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch pending invitations');
            }

            const { invites } = await response.json();
            console.log('Pending invites:', invites);
            setPendingInvites(invites);

        } catch (error) {
            console.error('Error fetching pending invites:', error);
            showSnackbar(error.message || 'Failed to fetch pending invitations', 'error');
        }
    };

    const handleUpdateProfile = async () => {
        setSaving(true);
        try {
            const { error } = await supabase.auth.updateUser({
                data: {
                    name: name,
                    avatar_url: avatarUrl
                }
            });

            if (error) throw error;

            // Dispatch custom event for layout update
            window.dispatchEvent(new CustomEvent('userProfileUpdate', {
                detail: {
                    avatar_url: avatarUrl,
                    name: name
                }
            }));

            showSnackbar('Profile updated successfully');
        } catch (error) {
            console.error('Error updating profile:', error);
            showSnackbar(error.message, 'error');
        } finally {
            setSaving(false);
        }
    };

    const handleSetDefaultWorkspace = async (workspaceId) => {
        try {
            const { error } = await supabase
                .rpc('set_default_workspace', {
                    p_tenant_id: workspaceId
                });

            if (error) throw error;
            
            // Refresh workspaces list
            await fetchWorkspaces();
            showSnackbar('Default workspace updated');
        } catch (error) {
            console.error('Error setting default workspace:', error);
            showSnackbar(error.message, 'error');
        }
    };

    const handleFileSelect = async (event) => {
        const file = event.target.files?.[0];
        if (!file) return;

        // Validate file type
        if (!['image/jpeg', 'image/png'].includes(file.type)) {
            showSnackbar('Please select a PNG or JPG file', 'error');
            return;
        }

        // Validate file size (1MB = 1048576 bytes)
        if (file.size > 1048576) {
            showSnackbar('File size must be less than 1MB', 'error');
            return;
        }

        try {
            setSaving(true);

            // Upload file to Supabase Storage
            const fileExt = file.name.split('.').pop();
            const fileName = `${userData.id}-${Math.random()}.${fileExt}`;
            const filePath = `avatars/${fileName}`;

            const { error: uploadError, data } = await supabase.storage
                .from('avatars')
                .upload(filePath, file);

            if (uploadError) throw uploadError;

            // Get public URL
            const { data: { publicUrl } } = supabase.storage
                .from('avatars')
                .getPublicUrl(filePath);

            console.log('New avatar URL:', publicUrl);

            // Update user metadata
            const { error: updateError } = await supabase.auth.updateUser({
                data: { 
                    avatar_url: publicUrl,
                    name: name
                }
            });

            if (updateError) throw updateError;

            setAvatarUrl(publicUrl);
            
            // Dispatch custom event for layout update
            window.dispatchEvent(new CustomEvent('userProfileUpdate', {
                detail: {
                    avatar_url: publicUrl,
                    name: name
                }
            }));

            showSnackbar('Avatar updated successfully');

        } catch (error) {
            console.error('Error uploading avatar:', error);
            showSnackbar(error.message, 'error');
        } finally {
            setSaving(false);
        }
    };

    const handleAcceptInvitation = async (inviteId) => {
        try {
            setSaving(true);
            const { data: { session } } = await supabase.auth.getSession();

            const response = await fetch(`${API_URL}/api/users/acceptinvitation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    invitation_id: inviteId
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to accept invitation');
            }

            showSnackbar('Invitation accepted successfully');
            
            // Refresh the lists
            await Promise.all([fetchPendingInvites(), fetchWorkspaces()]);
            
            // Dispatch event to update Layout component
            window.dispatchEvent(new CustomEvent('workspacesListUpdate'));

        } catch (error) {
            console.error('Error accepting invitation:', error);
            showSnackbar(error.message || 'Failed to accept invitation', 'error');
        } finally {
            setSaving(false);
        }
    };

    const handleDeclineInvitation = async (inviteId) => {
        try {
            setSaving(true);
            const { data: { session } } = await supabase.auth.getSession();

            const response = await fetch(`${API_URL}/api/users/declineinvitation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    invitation_id: inviteId
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to decline invitation');
            }

            showSnackbar('Invitation declined');
            // Refresh pending invites
            await fetchPendingInvites();

        } catch (error) {
            console.error('Error declining invitation:', error);
            showSnackbar(error.message || 'Failed to decline invitation', 'error');
        } finally {
            setSaving(false);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                User Settings
            </Typography>

            <Paper sx={{ p: 3, mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <Box
                        sx={{
                            position: 'relative',
                            '&:hover .avatar-edit': {
                                opacity: 1,
                            },
                        }}
                    >
                        <Avatar
                            src={avatarUrl}
                            sx={{
                                width: 100,
                                height: 100,
                                mr: 2,
                                border: '2px solid',
                                borderColor: 'divider'
                            }}
                        >
                            {name?.charAt(0) || userData?.email?.charAt(0)}
                        </Avatar>
                        <IconButton
                            className="avatar-edit"
                            sx={{
                                position: 'absolute',
                                right: 16,
                                bottom: 0,
                                backgroundColor: 'primary.main',
                                opacity: 0,
                                transition: 'opacity 0.2s',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                            }}
                            onClick={() => fileInputRef.current?.click()}
                            disabled={saving}
                        >
                            {saving ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                <EditIcon sx={{ color: 'white' }} />
                            )}
                        </IconButton>
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/png,image/jpeg"
                            style={{ display: 'none' }}
                            onChange={handleFileSelect}
                        />
                    </Box>
                    <Box>
                        <Typography variant="h6">
                            Profile Picture
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                            PNG or JPG (max. 1MB)
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        onClick={handleUpdateProfile}
                        disabled={saving}
                    >
                        {saving ? <CircularProgress size={24} /> : 'Save Changes'}
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => navigate('/user/reset-password')}
                    >
                        Reset Password
                    </Button>
                </Box>
            </Paper>

            <Paper sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Your Workspaces
                </Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Workspace Name</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell align="center">Default</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {workspaces.map((workspace) => (
                                <TableRow key={workspace.id}>
                                    <TableCell>{workspace.name}</TableCell>
                                    <TableCell>{workspace.role}</TableCell>
                                    <TableCell align="center">
                                        <Switch
                                            checked={workspace.isDefault}
                                            onChange={() => handleSetDefaultWorkspace(workspace.id)}
                                            disabled={workspace.isDefault}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {pendingInvites.length > 0 && (
                <Paper sx={{ p: 3, mt: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Pending Invitations
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Workspace Name</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Invited At</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pendingInvites.map((invite) => (
                                    <TableRow key={invite.id}>
                                        <TableCell>{invite.tenant_name}</TableCell>
                                        <TableCell>{invite.role || 'N/A'}</TableCell>
                                        <TableCell>
                                            {invite.created_at 
                                                ? new Date(invite.created_at).toLocaleDateString() 
                                                : 'N/A'
                                            }
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    disabled={saving}
                                                    onClick={() => handleAcceptInvitation(invite.id)}
                                                >
                                                    Accept
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    size="small"
                                                    disabled={saving}
                                                    onClick={() => handleDeclineInvitation(invite.id)}
                                                >
                                                    Decline
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            )}
        </Box>
    );
};

export default UserSettings; 