import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import ChatComponent from '../chat/ChatComponent';

const Configure = () => {
    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Lead Generation Chat
            </Typography>
            
            <Paper elevation={2} sx={{ p: 2 }}>
                <ChatComponent pageUrl={window.location.href} />
            </Paper>
        </Box>
    );
};

export default Configure; 