import React from 'react';
import { Grid, TextField } from '@mui/material';
import DataExtraction from './DataExtraction';

const BasicInfo = ({ funnel, handleChange, API_URL }) => {
  return (
    <Grid container spacing={3}>
      {/* Left Column */}
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Name"
          name="name"
          value={funnel.name}
          onChange={handleChange}
          margin="normal"
          required
        />

        <TextField
          fullWidth
          label="Description"
          name="description"
          value={funnel.data.description}
          onChange={handleChange}
          margin="normal"
          multiline
          rows={4}
        />
      </Grid>

      {/* Right Column */}
      <Grid item xs={12} md={6}>
        <DataExtraction 
          funnelId={funnel.id} 
          API_URL={API_URL}
        />
      </Grid>
    </Grid>
  );
};

export default BasicInfo; 