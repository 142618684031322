import React from 'react';
import { Box, Typography, Card, CardContent, CardActionArea, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const LeadGeneration = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Lead Generation
            </Typography>
            <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardActionArea onClick={() => navigate('/leadgen/configure')}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    Configuration
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Manage your lead generation settings and preferences
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardActionArea onClick={() => navigate('/leadgen/campaigns')}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    Campaigns
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    View and manage your lead generation campaigns
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default LeadGeneration; 