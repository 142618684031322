import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to refresh token
  const refreshToken = async () => {
    try {
      const { data: { session: newSession }, error } = await supabase.auth.refreshSession();
      if (error) throw error;
      
      setSession(newSession);
      return newSession;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return null;
    }
  };

  // Check if token is expired or about to expire (within 5 minutes)
  const isTokenExpired = (session) => {
    if (!session?.expires_at) return true;
    
    const expiresAt = session.expires_at * 1000; // Convert to milliseconds
    const now = Date.now();
    const fiveMinutes = 5 * 60 * 1000; // 5 minutes in milliseconds
    
    return now + fiveMinutes >= expiresAt;
  };

  useEffect(() => {
    const getSession = async () => {
      try {
        const { data: { session: currentSession } } = await supabase.auth.getSession();
        
        if (currentSession && isTokenExpired(currentSession)) {
          // Refresh token if expired or about to expire
          const newSession = await refreshToken();
          setSession(newSession);
        } else {
          setSession(currentSession);
        }
      } catch (error) {
        console.error('Error getting session:', error);
      } finally {
        setLoading(false);
      }
    };

    getSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (_event, session) => {
      if (session && isTokenExpired(session)) {
        const newSession = await refreshToken();
        setSession(newSession);
      } else {
        setSession(session);
      }
      setLoading(false);
    });

    // Set up periodic token refresh check
    const refreshInterval = setInterval(async () => {
      const { data: { session: currentSession } } = await supabase.auth.getSession();
      if (currentSession && isTokenExpired(currentSession)) {
        await refreshToken();
      }
    }, 5 * 60 * 1000); // Check every 5 minutes

    return () => {
      subscription.unsubscribe();
      clearInterval(refreshInterval);
    };
  }, []);

  const value = {
    session,
    loading,
    refreshToken, // Expose refresh function to consumers
    isTokenExpired // Expose token expiry check to consumers
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
} 