import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  IconButton, 
  Card, 
  CardContent, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  TextField,
  CircularProgress
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../../../context/AuthContext';

const DataColumns = ({ 
  dataColumns = [], 
  handleDataColumnChange, 
  addDataColumn, 
  removeDataColumn,
  API_URL 
}) => {
  const [properties, setProperties] = useState({});
  const [loadingProperties, setLoadingProperties] = useState({});
  const { session, refreshToken, isTokenExpired } = useAuth();

  useEffect(() => {
    dataColumns.forEach((column, index) => {
      if (column.object && !properties[column.object]) {
        fetchObjectProperties(column.object, index);
      }
    });
  }, []);

  const fetchObjectProperties = async (objectType, columnIndex) => {
    if (!API_URL) {
      console.error('API_URL is not defined');
      return;
    }

    setLoadingProperties(prev => ({ ...prev, [columnIndex]: true }));
    try {
      if (isTokenExpired(session)) {
        await refreshToken();
      }

      const response = await fetch(`${API_URL}/api/hubspot/object-properties`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session?.access_token}`
        },
        body: JSON.stringify({
          objectType: objectType
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.detail || 'Failed to fetch properties');
      }

      const data = await response.json();
      setProperties(prev => ({
        ...prev,
        [objectType]: data.properties || []
      }));
    } catch (error) {
      console.error('Error fetching properties:', error);
      setProperties(prev => ({
        ...prev,
        [objectType]: []
      }));
    } finally {
      setLoadingProperties(prev => ({ ...prev, [columnIndex]: false }));
    }
  };

  const handleObjectTypeChange = (index, value) => {
    handleDataColumnChange(index, 'object', value);
    handleDataColumnChange(index, 'property_label', '');
    handleDataColumnChange(index, 'property_name', '');
    handleDataColumnChange(index, 'data_type', '');
    
    if (value) {
      fetchObjectProperties(value, index);
    }
  };

  const handlePropertySelect = (index, property) => {
    if (property) {
      handleDataColumnChange(index, 'property_label', property.label);
      handleDataColumnChange(index, 'property_name', property.name);
      handleDataColumnChange(index, 'data_type', property.type);
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="subtitle1" sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 2,
        mb: 2 
      }}>
        Data Columns
        <IconButton 
          color="primary" 
          onClick={addDataColumn}
          size="small"
        >
          <AddCircleIcon />
        </IconButton>
      </Typography>

      {dataColumns.map((column, index) => (
        <Card key={index} sx={{ mb: 2 }}>
          <CardContent>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2 
            }}>
              <Typography variant="subtitle2">Column {index + 1}</Typography>
              <IconButton 
                color="error" 
                onClick={() => removeDataColumn(index)}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            </Box>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControl sx={{ flex: 1 }} size="small">
                <InputLabel>Object Type</InputLabel>
                <Select
                  value={column.object || ''}
                  label="Object Type"
                  onChange={(e) => handleObjectTypeChange(index, e.target.value)}
                >
                  <MenuItem value="contact">Contact</MenuItem>
                  <MenuItem value="company">Company</MenuItem>
                  <MenuItem value="deal">Deal</MenuItem>
                </Select>
              </FormControl>

              <Autocomplete
                sx={{ flex: 1 }}
                options={properties[column.object] || []}
                getOptionLabel={(option) => option.label || ''}
                value={properties[column.object]?.find(p => p.label === column.property_label) || null}
                onChange={(_, newValue) => handlePropertySelect(index, newValue)}
                disabled={!column.object}
                loading={loadingProperties[index]}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Property Label"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingProperties[index] ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />

              <TextField
                sx={{ flex: 1 }}
                size="small"
                label="Property Name"
                value={column.property_name || ''}
                disabled
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                sx={{ flex: 1 }}
                size="small"
                label="Data Type"
                value={column.data_type || ''}
                disabled
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default DataColumns; 