import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow,
    CircularProgress,
    Chip
} from '@mui/material';
import { supabase } from '../../supabaseClient';
import { useSnackbar } from '../../context/SnackbarContext';
import { useNavigate } from 'react-router-dom';

const CustomWorkflows = () => {
    const [workflows, setWorkflows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { showSnackbar } = useSnackbar();
    const API_URL = window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    useEffect(() => {
        fetchWorkflows();
    }, []);

    const fetchWorkflows = async () => {
        try {
            const { data: { session } } = await supabase.auth.getSession();
            
            const response = await fetch(`${API_URL}/api/workflows/list`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch workflows');
            }

            const data = await response.json();
            setWorkflows(data.workflows);
        } catch (error) {
            console.error('Error fetching workflows:', error);
            showSnackbar('Failed to load workflows', 'error');
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString();
    };

    const handleRowClick = (workflowId) => {
        navigate(`/custom-workflows/${workflowId}`);
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Custom Workflows
            </Typography>

            <Paper sx={{ mt: 3 }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Last Updated</TableCell>
                                <TableCell>Active</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        <CircularProgress size={24} />
                                    </TableCell>
                                </TableRow>
                            ) : workflows.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        No workflows found
                                    </TableCell>
                                </TableRow>
                            ) : (
                                workflows.map((workflow) => (
                                    <TableRow 
                                        key={workflow.id}
                                        onClick={() => handleRowClick(workflow.id)}
                                        sx={{ 
                                            cursor: 'pointer',
                                            '&:hover': { backgroundColor: 'action.hover' }
                                        }}
                                    >
                                        <TableCell>{workflow.name}</TableCell>
                                        <TableCell>
                                            <Chip 
                                                label={workflow.status}
                                                color={workflow.status === 'active' ? 'success' : 'default'}
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell>{formatDate(workflow.createdAt)}</TableCell>
                                        <TableCell>{formatDate(workflow.updatedAt)}</TableCell>
                                        <TableCell>
                                            <Chip 
                                                label={workflow.active ? 'Active' : 'Inactive'}
                                                color={workflow.active ? 'success' : 'error'}
                                                size="small"
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};

export default CustomWorkflows; 