import React from 'react';
import { Box, Typography, Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const linkStyles = {
    color: 'text.secondary',
    textDecoration: 'none',
    fontSize: '13px',
    transition: 'all 0.2s ease',
    '&:hover': {
        color: 'primary.main',
        textDecoration: 'underline'
    }
};

const PageHeader = ({ items }) => {
    return (
        <Box sx={{ mb: 3 }}>
            <MuiBreadcrumbs 
                separator={
                    <NavigateNextIcon 
                        fontSize="small" 
                        sx={{ color: 'text.disabled' }}
                    />
                }
                sx={{ mb: 1 }}
            >
                <RouterLink 
                    to="/"
                    style={{ textDecoration: 'none' }}
                >
                    <Typography
                        component="span"
                        sx={linkStyles}
                    >
                        Home
                    </Typography>
                </RouterLink>
                {items.map((item, index) => {
                    const isLast = index === items.length - 1;
                    
                    if (isLast) {
                        return (
                            <Typography 
                                key={item.text}
                                color="text.primary"
                                fontSize="13px"
                                fontWeight={500}
                            >
                                {item.text}
                            </Typography>
                        );
                    }

                    return (
                        <RouterLink
                            key={item.text}
                            to={item.href}
                            style={{ textDecoration: 'none' }}
                        >
                            <Typography
                                component="span"
                                sx={linkStyles}
                            >
                                {item.text}
                            </Typography>
                        </RouterLink>
                    );
                })}
            </MuiBreadcrumbs>
            <Typography 
                variant="h1"
                color="text.primary"
                fontWeight={600}
            >
                {items[items.length - 1].text}
            </Typography>
        </Box>
    );
};

export default PageHeader; 