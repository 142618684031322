import { useState } from 'react';
import { supabase } from '../../supabaseClient';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailChange = (e) => {
        const email = e.target.value;
        setEmail(email);
        if (email && !validateEmail(email)) {
            setEmailError('Please enter a valid email address');
        } else {
            setEmailError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !validateEmail(email)) {
            setEmailError('Please enter a valid email address');
            return;
        }

        setIsSubmitting(true);
        
        try {
            const API_URL = window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
            const response = await fetch(`${API_URL}/api/users/send-password-reset-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to send reset email');
            }
            
            setIsSubmitted(true);
        } catch (error) {
            console.error('Error:', error.message);
            setEmailError('Failed to process request. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <Box sx={{ 
                flex: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center', 
                padding: 4 
            }}>
                {!isSubmitted ? (
                    <Box sx={{ maxWidth: '400px', width: '100%' }}>
                        <Typography variant="h5" sx={{ mb: 3 }}>
                            Reset your password
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
                            Don't worry! It happens to the best of us. Enter your email address 
                            and we'll send you instructions to reset your password.
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit}>
                            <TextField
                                label="Email"
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                fullWidth
                                required
                                margin="normal"
                                error={!!emailError}
                                helperText={emailError}
                                disabled={isSubmitting}
                            />
                            <Button 
                                type="submit" 
                                variant="contained" 
                                color="primary"
                                fullWidth
                                size="large"
                                disabled={isSubmitting}
                                sx={{ mt: 2 }}
                            >
                                {isSubmitting ? 'Sending...' : 'Reset Password'}
                            </Button>
                            <Box sx={{ mt: 2, textAlign: 'center' }}>
                                <Link href="/login" variant="body2">
                                    Back to Login
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ maxWidth: '400px', width: '100%', textAlign: 'center' }}>
                        <Typography variant="h5" sx={{ mb: 3 }}>
                            Check your email
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
                            We've sent password reset instructions to {email}. 
                            Please check your email and follow the instructions to reset your password.
                        </Typography>
                        <Button 
                            variant="contained" 
                            color="primary"
                            fullWidth
                            href="/login"
                            size="large"
                        >
                            Back to Login
                        </Button>
                    </Box>
                )}
            </Box>
            <Box sx={{ 
                flex: 1, 
                backgroundImage: 'url(/images/signin_cover.jpg)', 
                backgroundSize: 'cover', 
                backgroundPosition: 'center' 
            }} />
        </Box>
    );
};

export default ForgotPassword;
