import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { supabase } from '../../supabaseClient';
import { useSnackbar } from '../../context/SnackbarContext';
import { Button, TextField, CircularProgress } from '@mui/material';
import ReactMarkdown from 'react-markdown';

const ChatComponent = ({ pageUrl }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const chatContainerRef = useRef(null);
  const { showSnackbar } = useSnackbar();
  const API_URL = window.env?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

  useEffect(() => {
    initializeChat();
  }, [pageUrl]);

  const initializeChat = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      // Check for existing chat session for this page
      const response = await fetch(`${API_URL}/api/chat/sessions?page_url=${pageUrl}`, {
        headers: {
          'Authorization': `Bearer ${session.access_token}`
        }
      });
      
      const data = await response.json();
      
      if (data && data.length > 0) {
        // Use the most recent session
        const mostRecentSession = data[0];
        setSessionId(mostRecentSession.id);
        
        // Load messages for this session
        const messagesResponse = await fetch(`${API_URL}/api/chat/messages/${mostRecentSession.id}`, {
          headers: {
            'Authorization': `Bearer ${session.access_token}`
          }
        });
        
        const messagesData = await messagesResponse.json();
        setMessages(messagesData);
      } else {
        // Create new session
        const createSessionResponse = await fetch(`${API_URL}/api/chat/session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.access_token}`
          },
          body: JSON.stringify({
            title: 'New Chat',
            page_url: pageUrl
          })
        });
        
        const newSession = await createSessionResponse.json();
        setSessionId(newSession.id);
      }
    } catch (error) {
      console.error('Error initializing chat:', error);
      showSnackbar('Failed to initialize chat', 'error');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || !sessionId) return;

    setLoading(true);
    
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      const response = await fetch(`${API_URL}/api/chat/message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        body: JSON.stringify({
          session_id: sessionId,
          content: input,
          chat_history: messages
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      const data = await response.json();
      
      // Add both user and assistant messages to the chat
      setMessages(prev => [...prev, data.user_message, data.assistant_message]);
      setInput('');

      // Scroll to bottom
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }

    } catch (error) {
      console.error('Error sending message:', error);
      showSnackbar('Failed to send message', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: '600px', 
      width: '100%', 
      maxWidth: '2xl', 
      border: '1px solid rgba(0, 0, 0, 0.12)', 
      borderRadius: '8px',
      backgroundColor: '#ffffff'
    }}>
      <div 
        ref={chatContainerRef}
        style={{
          flex: 1,
          overflowY: 'auto',
          padding: '16px',
          backgroundColor: '#f5f5f5'
        }}
      >
        {messages.map((message) => (
          <div
            key={message.id}
            style={{
              marginBottom: '16px',
              display: 'flex',
              justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start'
            }}
          >
            <div
              style={{
                padding: '12px 16px',
                borderRadius: '12px',
                maxWidth: '70%',
                backgroundColor: message.role === 'user' ? '#1976d2' : '#ffffff',
                color: message.role === 'user' ? '#ffffff' : '#000000',
                boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
                fontSize: '14px',
                lineHeight: '1.5'
              }}
            >
              {message.role === 'user' ? (
                message.content
              ) : (
                <ReactMarkdown
                  components={{
                    code: ({node, inline, className, children, ...props}) => {
                      const match = /language-(\w+)/.exec(className || '');
                      return !inline ? (
                        <pre style={{
                          backgroundColor: '#f5f5f5',
                          padding: '8px',
                          borderRadius: '4px',
                          overflowX: 'auto'
                        }}>
                          <code className={className} {...props}>
                            {children}
                          </code>
                        </pre>
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      );
                    },
                    // Style links
                    a: ({node, children, ...props}) => (
                      <a 
                        style={{ color: '#1976d2', textDecoration: 'underline' }} 
                        target="_blank"
                        rel="noopener noreferrer"
                        {...props}
                      >
                        {children}
                      </a>
                    ),
                    // Style paragraphs
                    p: ({node, children}) => (
                      <p style={{ margin: '8px 0' }}>{children}</p>
                    ),
                    // Style lists
                    ul: ({node, children}) => (
                      <ul style={{ paddingLeft: '20px', margin: '8px 0' }}>{children}</ul>
                    ),
                    ol: ({node, children}) => (
                      <ol style={{ paddingLeft: '20px', margin: '8px 0' }}>{children}</ol>
                    ),
                    // Style headings
                    h1: ({node, children}) => (
                      <h1 style={{ fontSize: '1.5em', margin: '16px 0 8px' }}>{children}</h1>
                    ),
                    h2: ({node, children}) => (
                      <h2 style={{ fontSize: '1.3em', margin: '14px 0 7px' }}>{children}</h2>
                    ),
                    h3: ({node, children}) => (
                      <h3 style={{ fontSize: '1.1em', margin: '12px 0 6px' }}>{children}</h3>
                    ),
                  }}
                >
                  {message.content}
                </ReactMarkdown>
              )}
            </div>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} style={{ 
        padding: '16px', 
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: '#ffffff'
      }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <TextField
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message..."
            variant="outlined"
            size="small"
            disabled={loading}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="medium"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Send'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChatComponent; 