import React from 'react';
import { Routes, Route, Navigate, useLocation, Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Box, Typography, IconButton, Avatar, Stack, Container, List, ListItem, Select, MenuItem as MuiMenuItem } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import DataObjectIcon from '@mui/icons-material/DataObject';
import Reports from './reports/reports';
import Datasets from './datasets';
import CohortMQLS from './reports/cohort/mqls';
import Funnels from './reports/funnels/funnels';
import FunnelForm from './reports/funnels/funnel-form';
import Conversions from './reports/conversions/conversions';
import { styled, useTheme } from '@mui/material/styles';
import UserSettings from './user/user_settings';
import { useSnackbar } from '../context/SnackbarContext';
import { useTenant } from '../context/TenantContext';
import WorkspaceSettings from './workspace/workspace_settings';
import { 
    Popover, 
    Card, 
    CardContent, 
    Button, 
    Divider, 
    Grid, 
    Tooltip 
} from '@mui/material';
import WorkspacesIcon from '@mui/icons-material/WorkspacesOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import PageHeader from '../context/PageHeader';
import { getBreadcrumbItems } from '../config/routes';
import ResetPassword from './auth/reset-password';
import CampaignIcon from '@mui/icons-material/Campaign';
import ShortTextIcon from '@mui/icons-material/ShortText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkflowIcon from '@mui/icons-material/AccountTree';
import LeadGeneration from './leadgen/leadgen';
import DataEnrichment from './enrichment/enrichment';
import FormShortening from './form-shortening/form-shortening';
import CustomWorkflows from './custom-workflows/custom-workflows';
import WorkflowDetails from './custom-workflows/workflow-details';
import Configure from './leadgen/Configure';
import Campaigns from './leadgen/Campaigns';

const LayoutWrapper = styled(Box)(({ theme }) => ({
  ...theme.components.MuiLayout.styleOverrides.root
}));

const Sidebar = styled(Box)(({ theme }) => ({
  ...theme.components.MuiLayout.styleOverrides.sidebar
}));

const Content = styled('main')(({ theme }) => ({
    flexGrow: 1,
    marginLeft: 280,
    transition: 'margin-left 0.3s ease',
    padding: theme.spacing(3),
    '&.collapsed': {
        marginLeft: 70
    }
}));

const MenuArea = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column'
}));

const SidebarFooter = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  marginTop: 'auto',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  '.collapsed &': {
    padding: theme.spacing(2, 1),
    alignItems: 'center'
  }
}));

const SidebarMenuItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  paddingLeft: theme.spacing(3),
  marginBottom: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  color: theme.palette.text.secondary,
  textDecoration: 'none',
  transition: 'all 0.2s ease',
  
  '.collapsed &': {
    padding: theme.spacing(1.5, 0),
    justifyContent: 'center',
    marginLeft: 0,
    marginRight: 0
  },

  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.primary.main
  },

  '&.active': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.selected,
    fontWeight: 500,

    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main
    }
  }
}));

const MenuText = styled('span')(({ theme }) => ({
  whiteSpace: 'nowrap',
  opacity: 1,
  transition: 'opacity 0.2s ease',
  '.collapsed &': {
    opacity: 0,
    width: 0,
    display: 'none'
  }
}));

const MenuIconWrapper = styled(Box)(({ theme }) => ({
  minWidth: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'inherit'
}));

const LogoutIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  flexShrink: 0,
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.hover
  }
}));

const Layout = ({ session }) => {
    const location = useLocation();
    const theme = useTheme();
    const [tenantName, setTenantName] = useState('');
    const [availableTenants, setAvailableTenants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const navigate = useNavigate();
    const [userAvatar, setUserAvatar] = useState(session?.user?.user_metadata?.avatar_url);
    const [userName, setUserName] = useState(session?.user?.user_metadata?.name);
    const { showSnackbar } = useSnackbar();
    const { activeTenantId } = useTenant();
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        const handleProfileUpdate = (event) => {
            const { avatar_url, name } = event.detail;
            setUserAvatar(avatar_url);
            setUserName(name);
        };

        window.addEventListener('userProfileUpdate', handleProfileUpdate);
        return () => window.removeEventListener('userProfileUpdate', handleProfileUpdate);
    }, []);

    useEffect(() => {
        const fetchTenantData = async () => {
            if (session) {
                try {
                    // First, fetch user roles and their associated tenants
                    const { data: userRolesData, error: rolesError } = await supabase
                        .from('user_roles')
                        .select(`
                            tenant_id,
                            is_default,
                            tenants (
                                id,
                                name
                            )
                        `)
                        .eq('user_id', session.user.id);

                    if (rolesError) throw rolesError;

                    setAvailableTenants(userRolesData.map(t => ({
                        id: t.tenant_id,
                        name: t.tenants.name,
                        isDefault: t.is_default
                    })));

                    // Get user's active tenant
                    const { data: userData, error: userError } = await supabase
                        .from('users')
                        .select('active_tenant_id')
                        .eq('id', session.user.id)
                        .single();

                    if (userError) throw userError;

                    // If no active tenant is set, use the default one
                    let currentActiveTenantId = userData.active_tenant_id;
                    if (!currentActiveTenantId) {
                        const defaultTenant = userRolesData.find(t => t.is_default);
                        if (defaultTenant) {
                            currentActiveTenantId = defaultTenant.tenant_id;
                            // Set the default tenant as active
                            await supabase
                                .rpc('switch_active_tenant', {
                                    p_user_id: session.user.id,
                                    p_tenant_id: defaultTenant.tenant_id
                                });
                        }
                    }

                    // Get active tenant details
                    if (currentActiveTenantId) {
                        const activeTenant = userRolesData.find(t => t.tenant_id === currentActiveTenantId);
                        if (activeTenant) {
                            setTenantName(activeTenant.tenants.name);
                        }
                    }

                    setLoading(false);
                } catch (error) {
                    console.error('Error in fetchTenantData:', error);
                    setLoading(false);
                }
            }
        };

        fetchTenantData();
    }, [session]);

    // Add event listener for workspace updates
    useEffect(() => {
        const handleWorkspaceUpdate = (event) => {
            const { tenantId, newName } = event.detail;
            
            // Update availableTenants with the new name
            setAvailableTenants(prev => prev.map(tenant => 
                tenant.id === tenantId 
                    ? { ...tenant, name: newName }
                    : tenant
            ));

            // Update tenantName if this is the active tenant
            if (session?.user?.active_tenant_id === tenantId) {
                setTenantName(newName);
            }
        };

        window.addEventListener('workspaceUpdate', handleWorkspaceUpdate);
        
        return () => {
            window.removeEventListener('workspaceUpdate', handleWorkspaceUpdate);
        };
    }, [session]);

    // Add this new useEffect for workspace list updates
    useEffect(() => {
        const handleWorkspacesListUpdate = async () => {
            try {
                setLoading(true);
                // Reuse the existing fetch logic
                const { data: userRolesData, error: rolesError } = await supabase
                    .from('user_roles')
                    .select(`
                        tenant_id,
                        is_default,
                        tenants (
                            id,
                            name
                        )
                    `)
                    .eq('user_id', session.user.id);

                if (rolesError) throw rolesError;

                setAvailableTenants(userRolesData.map(t => ({
                    id: t.tenant_id,
                    name: t.tenants.name,
                    isDefault: t.is_default
                })));

            } catch (error) {
                console.error('Error updating workspaces list:', error);
            } finally {
                setLoading(false);
            }
        };

        window.addEventListener('workspacesListUpdate', handleWorkspacesListUpdate);
        return () => window.removeEventListener('workspacesListUpdate', handleWorkspacesListUpdate);
    }, [session]);

    const handleTenantSwitch = async (tenantId) => {
        try {
            setLoading(true);
            
            const { error } = await supabase
                .rpc('switch_active_tenant', {
                    p_user_id: session.user.id,
                    p_tenant_id: tenantId
                });

            if (error) throw error;

            // Update UI
            const newTenant = availableTenants.find(t => t.id === tenantId);
            setTenantName(newTenant?.name || '');

            // Dispatch custom event for tenant switch
            window.dispatchEvent(new CustomEvent('tenantSwitch', {
                detail: { tenantId }
            }));

        } catch (error) {
            console.error('Error switching tenant:', error);
            showSnackbar('Failed to switch workspace', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleAvatarClick = () => {
        navigate('/user/settings');
    };

    // Handle workspace switcher open/close
    const handleWorkspaceSwitcherClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleWorkspaceSwitcherClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    // Add handler for workspace settings
    const handleWorkspaceSettings = () => {
        navigate('/workspace');
        handleWorkspaceSwitcherClose();
    };

    if (loading) {
        return (
            <div className="loading-container">
                <span className="loading-text">Loading...</span>
            </div>
        );
    }

    return (
        <LayoutWrapper>
            <Sidebar className={isCollapsed ? 'collapsed' : ''}>
                {/* Logo section */}
                <Box sx={{ 
                    p: 3,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    display: 'flex',
                    alignItems: 'center',
                    '.collapsed &': {
                        padding: theme.spacing(2, 1),
                        justifyContent: 'center'
                    }
                }}>
                    <img 
                        src="/images/logo-dark.png" 
                        alt="KevLog Logo" 
                        style={{ 
                            height: '32px',
                            width: 'auto',
                            display: isCollapsed ? 'none' : 'block',
                            marginRight: 'auto'
                        }} 
                    />
                    <IconButton
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        size="small"
                        sx={{
                            width: 40,
                            height: 40,
                            ml: isCollapsed ? 0 : 'auto',
                            '.collapsed &': {
                                margin: 0
                            }
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Box>

                <MenuArea>
                    <List sx={{ p: 0 }}>
                        <SidebarMenuItem 
                            component={Link} 
                            to="/reports"
                            className={location.pathname === '/reports' ? 'active' : ''}
                        >
                            <MenuIconWrapper>
                                <DashboardIcon />
                            </MenuIconWrapper>
                            <MenuText>Dashboards</MenuText>
                        </SidebarMenuItem>

                        <SidebarMenuItem 
                            component={Link} 
                            to="/leadgen"
                            className={location.pathname === '/leadgen' ? 'active' : ''}
                        >
                            <MenuIconWrapper>
                                <CampaignIcon />
                            </MenuIconWrapper>
                            <MenuText>Lead Generation</MenuText>
                        </SidebarMenuItem>

                        <SidebarMenuItem 
                            component={Link} 
                            to="/enrichment"
                            className={location.pathname === '/enrichment' ? 'active' : ''}
                        >
                            <MenuIconWrapper>
                                <DataObjectIcon />
                            </MenuIconWrapper>
                            <MenuText>Data Enrichment</MenuText>
                        </SidebarMenuItem>

                        <SidebarMenuItem 
                            component={Link} 
                            to="/form-shortening"
                            className={location.pathname === '/form-shortening' ? 'active' : ''}
                        >
                            <MenuIconWrapper>
                                <ShortTextIcon />
                            </MenuIconWrapper>
                            <MenuText>Form Shortening</MenuText>
                        </SidebarMenuItem>

                        <SidebarMenuItem 
                            component={Link} 
                            to="/custom-workflows"
                            className={location.pathname === '/custom-workflows' ? 'active' : ''}
                        >
                            <MenuIconWrapper>
                                <WorkflowIcon />
                            </MenuIconWrapper>
                            <MenuText>Custom Workflows</MenuText>
                        </SidebarMenuItem>
                    </List>
                </MenuArea>

                {/* Footer */}
                <SidebarFooter>
                    {/* Workspace Switcher Section */}
                    <Box
                        onClick={handleWorkspaceSwitcherClick}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1.5,
                            cursor: 'pointer',
                            py: 1,
                            pl: 0,
                            pr: 1,
                            width: '100%',
                            borderRadius: 1,
                            '&:hover': {
                                backgroundColor: theme.palette.action.hover
                            },
                            '.collapsed &': {
                                padding: 0,
                                width: 40,
                                justifyContent: 'center'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 40,
                                height: 40,
                                borderRadius: '50%',
                                bgcolor: theme.palette.primary.main,
                                flexShrink: 0,
                                color: theme.palette.primary.contrastText
                            }}
                        >
                            <WorkspacesIcon sx={{ fontSize: 24 }} />
                        </Box>

                        {!isCollapsed && (
                            <Box 
                                className="workspace-info"
                                sx={{ 
                                    flex: 1, 
                                    minWidth: 0,
                                    transition: 'color 0.2s ease-in-out',
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: 500,
                                        color: theme.palette.text.primary,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    {tenantName || 'Select Workspace'}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: theme.palette.text.secondary,
                                        display: 'block',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    Switch workspace
                                </Typography>
                            </Box>
                        )}
                    </Box>

                    {/* Workspace Switcher Popover */}
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleWorkspaceSwitcherClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        PaperProps={{
                            sx: { width: 320, maxHeight: 480 }
                        }}
                    >
                        <Card elevation={0}>
                            <CardContent>
                                <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'space-between', 
                                    alignItems: 'center',
                                    mb: 2 
                                }}>
                                    <Typography variant="h6">
                                        Workspaces
                                    </Typography>
                                    {activeTenantId && (
                                        <Tooltip title="Workspace Settings">
                                            <IconButton 
                                                size="small"
                                                onClick={handleWorkspaceSettings}
                                            >
                                                <SettingsIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Box>
                                <Grid container spacing={1}>
                                    {availableTenants.map(tenant => (
                                        <Grid item xs={12} key={tenant.id}>
                                            <Card
                                                variant="outlined"
                                                sx={{
                                                    cursor: 'pointer',
                                                    backgroundColor: 
                                                        tenant.id === activeTenantId 
                                                            ? theme.palette.primary.light 
                                                            : 'inherit',
                                                    '&:hover': {
                                                        borderColor: theme.palette.primary.main
                                                    }
                                                }}
                                                onClick={() => {
                                                    handleTenantSwitch(tenant.id);
                                                    handleWorkspaceSwitcherClose();
                                                }}
                                            >
                                                <CardContent>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <WorkspacesIcon color={tenant.id === activeTenantId ? 'primary' : 'inherit'} />
                                                        <Box sx={{ flex: 1 }}>
                                                            <Typography variant="body2" fontWeight={500}>
                                                                {tenant.name}
                                                            </Typography>
                                                            <Typography variant="caption" color="text.secondary">
                                                                {tenant.isDefault ? 'Default workspace' : 'Member'}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Popover>

                    {/* User Section */}
                    <Box
                        onClick={handleAvatarClick}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1.5,
                            cursor: 'pointer',
                            py: 1,
                            pl: 0,
                            pr: 1,
                            width: '100%',
                            borderRadius: 1,
                            '&:hover': {
                                backgroundColor: theme.palette.action.hover
                            },
                            '.collapsed &': {
                                padding: 0,
                                width: 40,
                                justifyContent: 'center'
                            }
                        }}
                    >
                        <Avatar
                            src={userAvatar}
                            sx={{
                                width: 40,
                                height: 40,
                                bgcolor: theme.palette.primary.main,
                                flexShrink: 0
                            }}
                        >
                            {userName?.charAt(0) || session?.user?.email?.charAt(0).toUpperCase()}
                        </Avatar>

                        {!isCollapsed && (
                            <>
                                <Box 
                                    className="user-info"
                                    sx={{ 
                                        flex: 1, 
                                        minWidth: 0,
                                        transition: 'color 0.2s ease-in-out',
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: 500,
                                            color: theme.palette.text.primary,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                    >
                                        {session?.user?.email || 'Guest'}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            color: theme.palette.text.secondary,
                                            display: 'block',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                    >
                                        {tenantName}
                                    </Typography>
                                </Box>

                                <LogoutIconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        supabase.auth.signOut();
                                    }}
                                    size="small"
                                >
                                    <LogoutIcon fontSize="small" />
                                </LogoutIconButton>
                            </>
                        )}
                    </Box>
                </SidebarFooter>
            </Sidebar>

            {/* Page Content */}
            <Content 
                flex={1}
                className={isCollapsed ? 'collapsed' : ''}
                id="page-content-wrapper"
            >
                <Box>
                    <PageHeader items={getBreadcrumbItems(location.pathname)} />
                    
                    <Routes>
                        <Route path="/" element={<Typography variant="h1">Welcome to Collextr tools</Typography>} />
                        <Route path="/reports" element={<Reports />} />
                        <Route path="/reports/lifecycle-cohort" element={<CohortMQLS />} />
                        <Route path="/reports/conversions" element={<Conversions />} />
                        <Route path="/reports/funnels" element={<Funnels />} />
                        <Route path="/workspace/" element={<WorkspaceSettings />} />
                        <Route path="/reports/funnels/new" element={<FunnelForm />} />
                        <Route path="/reports/funnels/:id/edit" element={<FunnelForm />} />
                        <Route path="/user/settings" element={<UserSettings />} />
                        <Route path="/user/reset-password" element={<ResetPassword />} />
                        <Route path="/workspace/settings" element={<WorkspaceSettings />} />
                        <Route path="/leadgen" element={<LeadGeneration />} />
                        <Route path="/enrichment" element={<DataEnrichment />} />
                        <Route path="/form-shortening" element={<FormShortening />} />
                        <Route path="/custom-workflows" element={<CustomWorkflows />} />
                        <Route path="/custom-workflows/:id" element={<WorkflowDetails />} />
                        <Route path="/leadgen/configure" element={<Configure />} />
                        <Route path="/leadgen/campaigns" element={<Campaigns />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </Box>
            </Content>
        </LayoutWrapper>
    );
}

export default Layout;