import React from 'react';
import { Box, Typography } from '@mui/material';

const DataEnrichment = () => {
    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Data Enrichment
            </Typography>
            <Typography variant="body1">
                This is the Data Enrichment page. Add your data enrichment content here.
            </Typography>
        </Box>
    );
};

export default DataEnrichment; 