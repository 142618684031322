import React from 'react';
import { 
  Box, 
  Typography, 
  IconButton, 
  Card, 
  CardContent, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  TextField,
  CircularProgress 
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

const StagesConfig = ({
  stages = [],
  hubspotProperties = [],
  hubspotDateProperties = [],
  loadingProperties,
  handleStageChange,
  addStage,
  removeStage
}) => {
  const handleHubspotPropertyChange = (index, value) => {
    const selectedProperty = hubspotProperties.find(prop => prop.name === value);
    handleStageChange(index, 'hs_property_enum_value', value);
    handleStageChange(index, 'type', 'hubspot enum');
    handleStageChange(index, 'name', ''); // Reset the stage name
  };

  const handleDatePropertyChange = (index, value) => {
    const selectedDateProperty = hubspotDateProperties.find(prop => prop.name === value);
    handleStageChange(index, 'date_property', value);
    handleStageChange(index, 'label_date', selectedDateProperty?.label || '');
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
        Stages
        <IconButton color="primary" onClick={addStage}>
          <AddCircleIcon />
        </IconButton>
      </Typography>

      {stages.map((stage, index) => (
        <Card key={index} sx={{ mb: 2 }}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="subtitle1">Stage {index + 1}</Typography>
              <IconButton color="error" onClick={() => removeStage(index)} size="small">
                <DeleteIcon />
              </IconButton>
            </Box>

            {/* First Row */}
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <TextField
                sx={{ width: '100px' }}
                label="Order"
                type="number"
                value={stage.order || ''}
                onChange={(e) => handleStageChange(index, 'order', parseInt(e.target.value))}
                size="small"
              />

              <FormControl sx={{ minWidth: '150px' }} size="small">
                <InputLabel>Type</InputLabel>
                <Select
                  value={stage.type || ''}
                  label="Type"
                  onChange={(e) => handleStageChange(index, 'type', e.target.value)}
                >
                  <MenuItem value="string">String</MenuItem>
                  <MenuItem value="hubspot enum">HubSpot Enum</MenuItem>
                </Select>
              </FormControl>

              {stage.type === 'hubspot enum' && (
                <FormControl sx={{ flex: 1 }} size="small">
                  <InputLabel>HubSpot Property</InputLabel>
                  <Select
                    value={stage.hs_property_enum_value || ''}
                    label="HubSpot Property"
                    onChange={(e) => handleHubspotPropertyChange(index, e.target.value)}
                    disabled={loadingProperties}
                  >
                    {loadingProperties ? (
                      <MenuItem disabled>Loading properties...</MenuItem>
                    ) : (
                      hubspotProperties.map((property) => (
                        <MenuItem key={property.name} value={property.name}>
                          {property.label} ({property.name})
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              )}
            </Box>

            {/* Second Row */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              {stage.type === 'hubspot enum' && stage.hs_property_enum_value ? (
                <FormControl sx={{ flex: 1 }} size="small">
                  <InputLabel>Stage Name</InputLabel>
                  <Select
                    value={stage.name || ''}
                    label="Stage Name"
                    onChange={(e) => {
                      const selectedProperty = hubspotProperties.find(
                        prop => prop.name === stage.hs_property_enum_value
                      );
                      const selectedOption = selectedProperty?.options?.find(
                        opt => opt.value === e.target.value
                      );
                      handleStageChange(index, 'name', e.target.value);
                      handleStageChange(index, 'label_name', selectedOption?.label || '');
                    }}
                  >
                    {hubspotProperties
                      .find(prop => prop.name === stage.hs_property_enum_value)
                      ?.options?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  sx={{ flex: 1 }}
                  label="Stage Name"
                  value={stage.name || ''}
                  onChange={(e) => handleStageChange(index, 'name', e.target.value)}
                  size="small"
                />
              )}

              <FormControl sx={{ flex: 1 }} size="small">
                <InputLabel>Date Property</InputLabel>
                <Select
                  value={stage.date_property || ''}
                  label="Date Property"
                  onChange={(e) => handleDatePropertyChange(index, e.target.value)}
                  disabled={loadingProperties}
                >
                  {loadingProperties ? (
                    <MenuItem disabled>Loading properties...</MenuItem>
                  ) : (
                    hubspotDateProperties.map((property) => (
                      <MenuItem key={property.name} value={property.name}>
                        {property.label} ({property.name})
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default StagesConfig; 