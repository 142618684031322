const palette = {
  primary: { 
    main: '#7f56d9',
    dark: '#6941c6',
    contrastText: '#fff'
  },
  secondary: { 
    main: '#344054',
    light: '#475467',
    dark: '#101828',
    contrastText: '#fff'
  },
  error: {
    main: '#d92d20',
    light: '#fda29b',
    dark: '#b42318',
    contrastText: '#fff'
  },
  success: {
    main: '#079455',
    contrastText: '#fff'
  },
  grey: {
    50: '#f9fafb',
    100: '#f2f4f7',
    200: '#eaecf0',
    300: '#d9d9d9',
    400: '#98a2b3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#101828',
  },
  background: {
    default: '#ffffff',
    paper: '#ffffff'
  },
  text: {
    primary: '#101828',
    secondary: '#344054',
    disabled: '#98a2b3'
  },
  divider: '#d0d5dd',
  action: {
    hover: 'rgba(127, 86, 217, 0.04)',
    selected: 'rgba(127, 86, 217, 0.08)',
    disabled: '#d9d9d9',
    disabledBackground: '#f2f4f7',
  },
  custom: {
    iconPrimary: '#667085',
    borderViolet: '#d6bbfb',
    borderGrey: '#d0d5dd',
    borderSubtleGrey: '#eaecf0',
  }
};

export default palette; 