import React from 'react';
import { 
    Box, 
    Typography, 
    Grid, 
    Card, 
    CardContent, 
    CardActionArea 
} from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import DescriptionIcon from '@mui/icons-material/Description';
import { useNavigate } from 'react-router-dom';

const Reports = () => {
    const navigate = useNavigate();

    const cardStyle = {
        width: 200,
        height: 150,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'transform 0.3s ease',
        cursor: 'pointer',
    };

    const inactiveCardStyle = {
        ...cardStyle,
        opacity: 0.5,
        cursor: 'not-allowed',
    };

    const inactiveStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    };

    const handleThumbnailClick = (path) => {
        navigate(path);
    };

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', minHeight: 'calc(100vh - 64px)' }}>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item>
                        <Card
                            style={cardStyle}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.transform = 'scale(1.05)';
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.transform = 'scale(1)';
                            }}
                        >
                            <CardActionArea onClick={() => handleThumbnailClick('/reports/lifecycle-cohort')}>
                                <CardContent>
                                    <TimelineIcon
                                        sx={{
                                            fontSize: 30,
                                            transition: 'color 0.3s',
                                            '&:hover': {
                                                color: 'green',
                                            },
                                        }}
                                    />
                                    <Typography variant="body2" component="div">
                                        Lifecycle Analysis (Cohorts)
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card
                            style={cardStyle}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.transform = 'scale(1.05)';
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.transform = 'scale(1)';
                            }}
                        >
                            <CardActionArea onClick={() => handleThumbnailClick('/reports/conversions')}>
                                <CardContent>
                                    <ShowChartIcon
                                        sx={{
                                            fontSize: 30,
                                            transition: 'color 0.3s',
                                            '&:hover': {
                                                color: 'green',
                                            },
                                        }}
                                    />
                                    <Typography variant="body2" component="div">
                                        Conversions
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    {[...Array(14)].map((_, index) => (
                        <Grid item key={index}>
                            <Card style={inactiveCardStyle}>
                                <CardContent style={inactiveStyle}>
                                    <DescriptionIcon style={{ fontSize: 30 }} />
                                    <Typography variant="body2" component="div">
                                        More Reports...
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default Reports; 