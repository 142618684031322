const typography = {
  fontFamily: `Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
  fontSize: 13,
  lineHeight: 1.5,
  h1: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: 1.2,
    color: 'text.primary'
  },
  h2: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: 1.3,
    color: 'text.primary'
  },
  h3: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: 1.4,
    color: 'text.primary'
  },
  h4: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 1.4,
    color: 'text.primary'
  },
  h5: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 1.5,
    color: 'text.primary'
  },
  h6: {
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: 1.5,
    color: 'text.primary'
  },
  body1: {
    fontSize: 13,
    lineHeight: 1.5
  },
  body2: {
    fontSize: 13,
    lineHeight: 1.5
  },
  subtitle1: {
    fontSize: 13,
    lineHeight: 1.5,
    fontWeight: 500
  },
  subtitle2: {
    fontSize: 12,
    lineHeight: 1.5,
    fontWeight: 500
  }
};

export default typography; 